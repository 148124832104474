<template>
  <div>
    <form id="ssoAccess" method="post" :action="ssoReturn">
      <input id="tokenInput" type="hidden" name="jwt" />
    </form>
  </div>
</template>

<script>
export default {
  name: "AccessSSO",
  computed: {
    ssoRequest() {
      return this.$store.state.auth.ssoRequest;
    },
    ssoReturn() {
      let url = "https://dataenergysupport.zendesk.com/access/jwt";

      if (this.$store.state.auth.ssoReturn) {
        url = url + "?&return_to=" + this.$store.state.auth.ssoReturn;
      }

      return url;
    }
  },
  created() {
    this.setup();
  },
  methods: {
    async setup() {
      const title = "Logging you in";
      const subtitle = "Please wait a moment";
      await this.$store.dispatch("loaderInitiate", { title, subtitle });

      this.$store
        .dispatch("accessSSO")
        .then(response => {
          this.pushJWT(response.data.token);
        })
        .catch(err => {
          this.$store.dispatch("loaderCancel");

          this.$swal({
            type: "error",
            title: "Oops...",
            text: err
          });

          this.$router.push("/");
        });
    },
    pushJWT(token) {
      document.getElementById("tokenInput").value = token;
      document.forms["ssoAccess"].submit();
    }
  }
};
</script>

<style scoped lang="scss"></style>
